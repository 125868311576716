body:not(.index) {
	#header  {
		background-position: 0 70%;
		.claimContainer {
			.claim, .btn, .phoneBtn  {
				display: none;
			}
			padding: rem(80px) 0;
			@include breakpoint(medium) {
				padding: rem(120px) 0;
			}
			@include breakpoint(full) {
				padding: rem(150px) 0;
			}
		}
	}
}


// HEADER
//////////////////////////////
body.ueberUns {
	#header{
		@include responsiveImage('/ueberuns/bgHeader_ueberuns.png', (tiny, medium), true);
		background-position: 0 50%;
		background-repeat: no-repeat;
    	background-size: cover;
	}
}
body.wohnungseigentuemergemeinschaftPhp {
	#header{
		@include responsiveImage('/wohnungseigentuemergemeinschaften/bgHeader_wohnungseigentuemergemeinschaften.png', (tiny, medium), true);
		background-position: 0 50%;
		background-repeat: no-repeat;
    	background-size: cover;
	}
}
body.miethaeuser {
	#header{
		@include responsiveImage('/miethauser/bgHeader_miethaeser.png', (tiny, medium), true);
		background-position: 0 50%;
		background-repeat: no-repeat;
    	background-size: cover;
	}
}
body.proVentusApp {
	#header{
		height: rem(250px);
		@include responsiveImage('/proventusapp/bgHeader_proVentusApp.jpg', (tiny, medium), true);
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@include breakpoint(tiny) {
			height: rem(300px);
		}
		@include breakpoint(medium) {
			height: rem(545px);
		}
	}
}
#header {
	position: relative;
	width: 100%;
	margin-top: rem(70px);
	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), true);
	@include breakpoint($break) {
		margin-top: rem(110px);
	}
	@include breakpoint(full) {
		margin-top: rem(130px);
	}
	&.lazyLoaded {
		background-size:cover;
		background-position: left;
	}
	.claimContainer {
		position: relative;
		width: 100%;
		padding: rem(80px) 0;
		max-width: rem(670px);
		@include breakpoint(medium) {
			padding: rem(120px) 0;
		}
		@include breakpoint(giant) {
			padding: rem(160px) 0;
			max-width: rem(800px);
		}
		@include breakpoint(full) {
			padding: rem(250px) 0;
			max-width: 100%;
		}
		@include breakpoint(cut) {
			padding: rem(320px) 0;
		}
		.claim {
			color: $dark;
			font-size: rem(32px);
			line-height: rem(35px);
			margin-bottom: rem(30px);
			span {
				display: block;
				text-transform: uppercase;
				font-size: rem($baseFontSize);
				line-height: rem(28px);
			}
			@include breakpoint(small) {
				font-size: rem(40px);
				line-height: rem(45px);
				span {
					font-size: rem(24px);
					line-height: rem(32px);
				}
			}
			@include breakpoint(giant) {
				font-size: rem(45px);
				line-height: rem(45px);
				span {
					font-size: rem(30px);
					line-height: rem(32px);
				}
			}
			@include breakpoint(full) {
				font-size: rem(50px);
				line-height: rem(70px);
				span {
					font-size: rem(24px);
					line-height: rem(32px);
				}
			}
		}
		.btn, .phoneBtn {
			display: block;
			@include breakpoint(tiny) {
				display: inline-block;
			}
			
		}
		.btn {
			@include breakpoint(tiny) {
				margin-right: rem($baseGap);
			}
		}
		.phoneBtn {
			text-align: center;
			background: $light;
			color: $dark;
			line-height: rem(24px);
			padding: rem(20px) rem(25px);
			text-align: center;
			font-size: rem(16px);
			margin-top: rem($baseGap);
			span {
				display: block;
				@include breakpoint(tiny) {
					display: inline-block;
					margin-left: rem(10px);
				}
				
			}
			@include breakpoint(giant) {
				font-size: rem($baseFontSize);
			}
			
		}
	}
}

#navigation {
	.branding {
		display: none;
		@include breakpoint($break) {
			margin-left: rem($baseGap);
			display: inline-block;
			position: absolute;
		    top: 50%;
		    left: 0;
		    max-width: rem(200px);
		    transform: translateY(-50%);
		    width: 100%;
			transition: 300ms linear;
		 }
		@include breakpoint(full) {
			max-width: rem(300px);
		}
		
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	overflow-x: hidden;
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}
//PADDING
//////////////////////////////
.firstContent, .secondContent, .contactForm, .mapContainer, body:not(.index) .contentContainer{
	padding: rem(80px) 0;
	@include breakpoint(large) {
		padding: rem(150px) 0;
	}
}

//MAPCONTAINER
//////////////////////////////
.mapContainer {
	@include responsiveImage('index/bgMap.jpg', (tiny, medium, large), true);

	&.lazyLoaded {
		background-size:cover
	}
	
	.white {
		background: rgba($light, .9);
		padding: rem(40px) rem(20px);
		@include breakpoint(small) {
			padding: rem(50px);
		}
		@include breakpoint(large) {
			padding: rem(60px) rem(40px);
		}
		@include breakpoint(huge) {
			padding: rem(80px);
		}
		p.usp {
			font-size: rem(20px);
			line-height: rem(25px);
			margin-bottom: rem(20px);
			.highlight {
				color: $primary;
				font-weight: 600;
			}
			@include breakpoint(medium) {
				font-size: rem(24px);
				line-height: rem(35px);
				margin-bottom: rem(40px)
			}
			@include breakpoint(large) {
				margin-bottom: rem(50px);
			}
			@include breakpoint(huge) {
				font-size: rem(28px);
			}
		}
	}
}
//CONTACTFORM
//////////////////////////////
.contactForm {
	@include responsiveImage('index/bgContact.jpg', (tiny, medium, large), true);

	&.lazyLoaded {
		background-size:cover
	}
	p.h2, .h2 span {
		color: $light;
	}
	form.default {
		color: $light;
		::-webkit-input-placeholder {color: $light;}
		::-moz-placeholder {color: $light !important;}
		:-ms-input-placeholder {color: $light !important;}
		:-moz-placeholder {color: $light;}
		label[for="Datenschutz"] {
			a {
				color: $light;
				text-decoration: underline;
				&:hover, &:active, &:focus {
					color: $primary;
					text-decoration: none;
				}
			}
		}
	}
}

//CONTACTBAR
.contactBar {
	padding: rem(80px) 0;
	font-size: rem(16px);
	@include breakpoint(huge) {
		font-size: rem($baseFontSize);
	}
	@include breakpoint(giant) {
		p, dl, .logo img {
			margin-bottom: 0;
		}
		p.h2 {
			margin-bottom: 1.5rem;
		}
	 }
	
	@include responsiveImage('layout/bgSkyline.png', (tiny, medium), true);
	&.lazyLoaded {
		background-size:contain; 
		background-position: bottom;
		@include breakpoint(giant) {
			background-size: cover;
			background-position: center;
		}
	}
	.times {
		width: 100%;
		line-height: rem(24px);
		@include breakpoint(huge) {
			line-height: rem($baseLineHeight);
		 }
		
		dt, dd {
			padding: 0!important;
			margin: 0 !important;
			background: transparent!important;
		}
		dt {
			width: 100%;
			float: left;
			font-weight: 300;
			@include breakpoint(small) {
				width: 35%;
			}
			
		}
		dd {
			width: 100%;
			float: left;
			span {
				display: block;
				margin-bottom: 15px;
			}
			@include breakpoint(small) {
				width: 65%;
				span {
					display: inline-block;
					margin-bottom: 0;
					&:before {
						content: "|";
						margin: 0 rem(10px);
					}
				}
			}
		}
	}

	.logo {
		max-width: rem(250px);
		width: 100%;
		display: inline-block;
	}
}
// FOOTER
//////////////////////////////

#footer {
	background: $light;
	border-top: 1px solid $medium;
	padding: rem(15px) 0;
	@include breakpoint(small) {
		padding: 0;
	}
}

body.internetExplorer {
	svg {
		@include breakpoint(small) {
			height: rem(300px);
			width: auto;
		}
		@include breakpoint(large) {
			height: rem(415px);
			width: auto;
		}
	}
}

body.index .secondContent{
	padding-top: 0rem;
}

// START PROVENTUS APP 
////////////////////////////
.appstore {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	img { 
		width: 250px;
	}
	@include breakpoint(small) {
		flex-direction: row;
	}
	@include breakpoint(large) {
		img {
			width: 300px;
		}
		flex-direction: column;
		margin-top: 6rem;
	}
	@include breakpoint(giant) {
		margin-top: 4rem;
	}
	@include breakpoint(full) {
		margin-top: 2rem;
	}
}

.idwell-app {
	width: 100px;
}
.center {
	display: flex;
	justify-content: center;
}
@include breakpoint(small) {
	.order {
		display: flex;
	}
	.idwell-app {
		width: 90px;
		margin-left: 2rem;
	}
}
@include breakpoint(medium) {
	.idwell-app {
		width: 100px;
	}
}
@include breakpoint(giant) {
	.idwell-app {
		width: 107px;
	}
}
// proVentus App index.php
///////////////////////

.appstore2 {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	img { 
		width: 180px;
	}
	@include breakpoint(tiny) {
		flex-direction: row;
	}
	@include breakpoint(small) {
		img {
			width: 200px;
		}
	}
	
}
// END PROVENTUS APP
///////////////////////

.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			background: $light;
			max-height: 90vh;
			max-width: calc(100% - #{rem(20px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
			padding: 10px;
			@include breakpoint(tiny) {
				max-width: calc(100% - #{rem(80px)});
				padding: 0;
			}
	 
			@include breakpoint(large) {
				max-width: 60%;
			}
	 
			@include breakpoint(full) {
				max-width: 50%;
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}