// Use this for default Icomoon

// @include fontFaceV2(
//  $fontName: "icomoon",
//  $fileName: "icomoon",
//  $types: (woff, ttf, svg, eot),
//  $style: normal,
// );

@include fontFaceV2(
    $fontName: "Nunito Sans",
    $fileName: "nunito-sans-v5-latin",
    $weights: ("300", "600"),
    $types: ("woff2","woff")
);