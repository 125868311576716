/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 30px;

$h1Size: 40px;

$h2Size: 35px;

$h3Size: 28px;

$h4Size: 25px;

$h5Size: 22px;

$h6Size: 16px;

$mainFont: "Nunito Sans", sans-serif;

$displayFont: "Nunito Sans", sans-serif;

$iconFont: icomoon, sans-serif;

// Project colors

$primary: #c6aa76;
$secondary: #0a3e8a;
$light: #fff;
$medium: #F2F2F0;
$dark: #464646;
$border: #bbb;
$alert: #a68c5c;

$baseGap: 18px;

$rowMaxWidth: 1600px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "×", 
	minus: "-", 
	angle-right: "❯", 
	plus: "+", 
	angle-up: "↑", 
	exclamation: "!", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
	),
	tiny: (
		width: em(480px, $bpContext), 
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (4, 8, 6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (6),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (2, 3, 4, 6, 7, 10),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 5, 8),
		suffix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

