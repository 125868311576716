// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-weight: 300;
	transition: 300ms linear;
	@include breakpoint($break) {
		font-size: rem(16px);
		padding: 0;
	}
	@include breakpoint(full) {
		font-size: rem($baseFontSize);
	 }
	
}
%buttonHover {
	// Link Hover
	color: $primary;
}
%buttonActive {
	// Link Active
	color: $primary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 70px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		.row {
			transition: 300ms linear;
			padding: rem(40px) 0;
			@include breakpoint(full) {
				padding: rem(50px) 0;
			}
		}
		
		nav {
			max-width: rem($rowMaxWidth);
			margin: 0 auto;
			width: 100%;
			ul {
				&.navi {
					width: 78%;
					float: right;
					padding: 0 rem($baseGap);
					@include breakpoint(full) {
						max-width: rem(1100px);
						width: 100%;
					 }
					
					li {
						&.hasSub {
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
		&.scroll {
			.row {
				padding: rem(30px) 0;

				.branding {
					@include breakpoint(full) {
						max-width: rem(260px);	
					 }
					
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $medium;
	color: $dark;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

	.branding {
		font-size: rem(25px);
		letter-spacing: 1px;
		span {
			color: $dark;
		}
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	max-width: rem(600px);
	@include breakpoint(small) {
		justify-content: space-between;
		padding: rem(30px) 0;
	}
	@include breakpoint(large) {
		padding: rem(40px) 0;
	}
	
	li {
		flex-basis:100%;
		width: 100%;
		display: inline-block;
		border-bottom: 1px solid $medium;
		&:last-child {
			border-bottom: 0;
		}
		@include breakpoint(small) {
			flex-basis: auto;
			width: auto;
			border-bottom: 0;
		}
		
		a {
			color: $dark;
			display: inline-block;
			width: 100%;
			padding: rem(15px) rem(20px);
			font-weight: 300;
			transition: 300ms linear;
			

			@include breakpoint(small) {
				width: auto;
				padding: 0;
				font-size: rem(16px);
			}
			@include breakpoint($break) {
				font-size: rem($baseFontSize);
			 }
			
			&:hover, &:focus, &.active {
				color: $primary;
			}
		}
	}
}